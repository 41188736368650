###
#
# @file REIP - the new REIP ("Choose Both") pages
#
###

import Cookies from 'js-cookie'
import '@accessible360/accessible-slick/slick/slick'
import { debounce } from 'throttle-debounce'

import { gsap } from './vendor/gsap/gsap-core.js'
import { TweenLite, CSSPlugin, ScrollTrigger, ScrollToPlugin, DrawSVGPlugin, MotionPathPlugin, MorphSVGPlugin, Draggable } from './vendor/gsap/all.js'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin, MotionPathPlugin, MorphSVGPlugin, Draggable)

window.gsap = gsap
window.ScrollTrigger = ScrollTrigger
window.ScrollToPlugin = ScrollToPlugin
window.DrawSVGPlugin = DrawSVGPlugin
window.MotionPathPlugin = MotionPathPlugin
window.MorphSVGPlugin = MorphSVGPlugin
window.Draggable = Draggable

class REIP

  constructor: ()->
    __Purpose.register_barba_event_callback
      func: @init
      event: 'transitionCompleted'

    @base_duration = 0.3
    @defaults =
      duration: @base_duration
      ease: 'Power4.easeInOut'
    @morphDefaults =
      duration: @base_duration
      transformOrigin: 'center'
      ease: 'Power4.easeInOut'
    @timelines = []



    $(document)
    .on 'click', '[data-offcanvas-nav-choose-both]', @nav_choose_both_toggle
    # .on 'mouseenter', '[data-explore-area]', @explore_area_enter
    # .on 'mouseleave', '[data-explore-area]', @explore_area_leave
    .on 'click', '.wrap-transcript_cta-read', @audio_transcript_toggle
    .on 'click', '.explore-more-areas_btn-slide', @slider_control
    .on 'click', '[data-reip-footer-toggle]', @footer_content_toggle
    .on 'click', '.btn-tap', @start_jigsaw_timeline
    .on 'click', '#cta_hear_a_scenario', @toggle_audio
    .on 'click', '#cta_guide', @scroll_to_guide
    .on 'click', '[data-explore-slide]', @explore_areas_slider_click
    .on 'click', '[data-next-section]', @next_section_link_click


    $(window).on 'resize', debounce(100, @resize_reloader)
    window.addEventListener("message", @receiveMessage, false)



  init: ()=>
    $('body').removeClass('scroll-locked')
    @is_landing = $('body').hasClass('page-template-template-reip-landing')
    @is_detail = $('body').hasClass('page-template-template-reip-detail')

    if @is_detail
      @theme = $('.reip-container').data('theme')
      @audio_file = $('.reip-container').data('audio')

    @both_chosen = false
    @animation_choose_both = false
    # garbage collect any animations etc before setting up new ones
    @teardown()

    $(document).ready =>
      @set_last_breakpoint()
      if __Purpose.Foundation.MediaQuery.atLeast('xlarge')
        @init_desktop()
      else
        @init_mobile()
      $('body').removeClass 'op0' if gsap
      $('.reip-container').removeClass 'op0'


  teardown: =>
    console.log "killed #{@timelines.length} tweens" if @timelines.length
    tl.kill() for tl in @timelines # make sure we garbage collect timelines if the user moves off the page
    if @is_detail
      @audio?.pause()
      @explore_areas_slider?.slick 'unslick'
      @detail_template_hero_slider?.slick 'unslick'

  init_desktop: =>
    console.log 'desktop init'
    @jigsaw_timeline = @landing_choose_both_animation() if @is_landing
    @landing_desktop() if @is_landing
    @landing_explore_area_scrolling() if @is_landing
    if @is_detail
      @audioplayer()
      @detail_template_sliders()
      @svg_transitions()
      setTimeout( => # prevents race condition
        @svg_scrolltrigger()
      , 250)

    # window.dd = @detail_template_sliders

  init_mobile: =>
    console.log 'mobile init'
    @jigsaw_timeline = @landing_choose_both_animation() if @is_landing
    @landing_explore_area_scrolling() if @is_landing
    if @is_detail
      # $('body').addClass('scroll-locked') if window.scrollY is 0
      @audioplayer()
      @detail_template_sliders()
      @svg_transitions()
      @slick_controls_svgs()
      $("header").addClass 'op0' # force hide on mobile so it doesn't cover the SVG


  landing_explore_area_scrolling: ()=>
    unless __Purpose.Foundation.MediaQuery.atLeast('large')
      blocks = ("#explore-area-0#{i}-trigger" for i in [1..5])
      for block in blocks
        do(block) =>
          @timeline(
            defaults: @defaults
            scrollTrigger:
              trigger: block
              start: 'top center'
              end: 'bottom center'
              onEnter: ->
                $(block).addClass('active')
              onEnterBack: ->
                $(block).addClass('active')
              onLeave: ->
                $(block).removeClass('active')
              onLeaveBack: ->
                $(block).removeClass('active')
          )
      @timeline(
        defaults: @defaults
        scrollTrigger:
          trigger: '#reip-hero'
          start: 'top top-=1px'
          end: 'bottom top'
          onEnter: @start_jigsaw_timeline
      )





  landing_desktop: =>
    intro = $('#intro')
    who_we_are = $('#who-we-are')
    who_you_are = $('#who-you-are')
    this_experience = $('#this-experience')
    final_trigger = $('#final-trigger')
    intro_img = $('#drag_image_0')
    who_we_are_img= $('#drag_image_1')
    who_you_are_img = $('#drag_image_2')
    this_experience_img = $('#drag_image_3')
    right_img_c = $('.reip-drag-container')
    # determine offset of left side top section
    top = ($(window).height() / 2) - ( right_img_c.height() / 2 )
    width = right_img_c.width()
    right_img_c.addClass('fixed')

    @timelines.push gsap.set(right_img_c, {top: top, width: width})
    @timelines.push gsap.set(who_we_are_img, {opacity: 0})
    @timelines.push gsap.set(who_you_are_img, {opacity: 0})
    @timelines.push gsap.set(this_experience_img, {opacity: 0})
    @timelines.push gsap.set(who_we_are, {y: '100%'})
    @timelines.push gsap.set(who_you_are, {y: '100%'})
    @timelines.push gsap.set(this_experience, {y: '100%',opacity: 1})
    @timelines.push gsap.set(final_trigger, {opacity: 0})


    landing_t = @intro_timeline = @timeline(
      defaults: @defaults
    )

    .addLabel('who_we_are', 0)
    .addLabel('who_we_are_end', "who_we_are+=1")
    .addLabel('who_you_are', "who_we_are_end")
    .addLabel('who_you_are_end', "who_you_are+=1")
    .addLabel('this_experience', "who_you_are_end")
    .addLabel('this_experience_end', "this_experience+=1")

    .to(intro, {y: '-100%', duration: 1}, 'who_we_are')
    .to(intro_img, {opacity: 0, duration: 1}, 'who_we_are')
    .to(who_we_are, {y: '0', duration: 1}, 'who_we_are')
    .to(who_we_are_img, {opacity: 1, duration: 1},  'who_we_are')

    .to(who_we_are, {y: '-100%', duration: 1}, 'who_you_are')
    .to(who_we_are_img, {opacity: 0, duration: 1},  'who_you_are')
    .to(who_you_are, {y: '0', duration: 1}, 'who_you_are')
    .to(who_you_are_img, {opacity: 1, duration: 1},  'who_you_are')

    .to(who_you_are, {y: '-100%', duration: 1}, 'this_experience')
    .to(who_you_are_img, {opacity: 0, duration: 1},  'this_experience')
    .to(this_experience, {y: '0', duration: 1}, 'this_experience')
    .to(this_experience_img, {opacity: 1, duration: 1},  'this_experience')
    .pause()

    @timeline(
      scrollTrigger:
        trigger: '#who-we-are-trigger'
        start: 'top 80%'
        end: 'bottom center'
        onEnter: @start_jigsaw_timeline
    )

    @timeline(
      scrollTrigger:
        trigger: '#who-we-are-trigger'
        start: 'top center'
        end: 'bottom center'
        onEnter: ->
          landing_t.tweenTo('who_we_are_end')
    )

    @timeline(
      scrollTrigger:
        trigger: '#who-you-are-trigger'
        start: 'top center'
        end: 'bottom center'
        onEnter: ->
          landing_t.tweenTo('who_you_are_end')
        onLeaveBack: ->
          landing_t.tweenTo('who_you_are')
    )

    @timeline(
      scrollTrigger:
        trigger: '#this-experience-trigger'
        start: 'top center'
        end: 'bottom center'
        onEnter: ->
          landing_t.tweenTo('this_experience_end')
        onLeaveBack: ->
          landing_t.tweenTo('this_experience')
    )

    @timeline(
      scrollTrigger:
        trigger: '#final-trigger'
        start: 'bottom bottom'
        end: 'bottom center'
        onEnter: =>
          right_img_c.removeClass('fixed')
          right_h = right_img_c.height()
          spacer_h = $(".w43.spacer").height()
          v_slide_offset = 90
          top = ((spacer_h - right_h) / 2 * -1.0 - (v_slide_offset / 2))
          @timelines.push gsap.set(right_img_c, {top: top})
          @timelines.push gsap.set(this_experience, {opacity: 0})
          @timelines.push gsap.set(final_trigger, {opacity: 1})
        onLeaveBack: =>
          right_img_c.addClass('fixed')
          top = ($(window).height() / 2) - ( right_img_c.height() / 2 )
          @timelines.push gsap.set(right_img_c, {top: top})
          @timelines.push gsap.set(this_experience, {opacity: 1})
          @timelines.push gsap.set(final_trigger, {opacity: 0})
    )


    @drag1 = Draggable.create(".reip-drag-draggable-handle.top.left",
      type:"x,y",
      bounds: document.querySelector('.reip-drag-container')
      inertia: false,
      onDrag: (control)->
        p1 = @endX / @maxX
        p2 = @endY / @maxY
        perc = ( p1 + p2 ) / 2 #average of x and y progress
        if perc >= 0.23
          control.start_jigsaw_timeline()
          control.drag1.disable()
          control.drag2.disable()
        else
          control.jigsaw_timeline.progress perc
      onDragParams: [this]
      onDragEnd: (control) ->
        unless control.jigsaw_timeline.isActive()
          control.timelines.push gsap.to control.jigsaw_timeline, {progress: 0, duration: 0.3}
          control.timelines.push gsap.to @target, {x: 0, y: 0, duration: 0.3}
      onDragEndParams: [this]
    )[0] # for some reason the create method returns an array
    @timelines.push @drag1


    @drag2 = Draggable.create(".reip-drag-draggable-handle.bottom.right",
      type:"x,y",
      bounds: document.querySelector('.reip-drag-container')
      inertia: false,
      onDrag: (control)->
        p1 = @endX / @minX
        p2 = @endY / @minY
        perc = ( p1 + p2 ) / 2 #average of x and y progress
        if perc >= 0.23
          control.start_jigsaw_timeline()
          control.drag1.disable()
          control.drag2.disable()
        else
          control.jigsaw_timeline.progress perc
      onDragParams: [this]
      onDragEnd: (control) ->
        unless control.jigsaw_timeline.isActive()
          control.timelines.push gsap.to control.jigsaw_timeline, {progress: 0, duration: 0.3}
          control.timelines.push gsap.to @target, {x: 0, y: 0, duration: 0.3}
      onDragEndParams: [this]
    )[0]
    @timelines.push @drag2


  landing_choose_both_animation: ()=>
    # Get main container measures
    drag_image_container_height = $('.reip-drag-image').height()
    drag_image_container_width = $('.reip-drag-image').width()

    # Get each box width and height
    left_label_width = $('.reip-drag-draggable.top.left').width()
    right_label_width = $('.reip-drag-draggable.bottom.right').width()
    left_label_height = $('.reip-drag-draggable.top.left').height()
    right_label_height = $('.reip-drag-draggable.bottom.right').height()

    #  Calc positions
    left_label_top_pos = (drag_image_container_height / 2) - (left_label_height / 2)
    right_label_bottom_pos = (drag_image_container_height / 2) - (right_label_height / 2)

    label_x_distance = (drag_image_container_width - left_label_width - right_label_width) / 2

    duration = .3

    reip_image_container_w = if __Purpose.Foundation.MediaQuery.atLeast('xlarge') then '400px' else '245px'
    reip_image_container_h = if __Purpose.Foundation.MediaQuery.atLeast('xlarge') then '600px' else '370px'

    choose_top_pos = if __Purpose.Foundation.MediaQuery.atLeast('xlarge') then '-114px' else '19px'
    choose_left_pos = if __Purpose.Foundation.MediaQuery.atLeast('xlarge') then '106px' else '43px'

    both_bottom_pos = if __Purpose.Foundation.MediaQuery.atLeast('xlarge') then '-117px' else '16px'
    both_right_pos = if __Purpose.Foundation.MediaQuery.atLeast('xlarge') then '24px' else '-25px'


    animation_tl = @timeline(
      defaults: @defaults
    )
    .pause()
    .addLabel('start', 0)
    .addLabel('phase2', 1)
    .fromTo(".btn-tap", {opacity: 1}, { opacity: 0, duration: .1, ease: 'none' }, 'start')
    .fromTo(".reip-drag-draggable.top.left", {top: 0, left: 0}, { top: left_label_top_pos, left: label_x_distance, duration: duration, ease: 'none' }, 'start')
    .fromTo(".reip-drag-draggable.bottom.right", {bottom: 0, right: 0}, { bottom: right_label_bottom_pos - 1, right: label_x_distance, duration: duration, ease: 'none' }, 'start')
    .fromTo(".reip-drag-draggable .label .hidden", {opacity: 0}, { opacity: 1, duration: duration, ease: 'none' }, 'start')
    .fromTo(".reip-drag-draggable .label .visible", {opacity: 1}, { opacity: 0, duration: duration, ease: 'none' }, 'start')
    .fromTo(".reip-drag-draggable .label .draggable-img-pattern", {opacity: 0}, { opacity: 1, duration: duration, ease: 'none' }, 'start')
    .to(".reip-drag-draggable .label.remove-border", { borderTopWidth:0,borderRightWidth:0,borderBottomWidth:0, borderLeftWidth:0,backgroundColor:'transparent',top: 0 }, 'start')
    .fromTo(".reip-image", { width: '0px', height: '0px' }, { width: reip_image_container_w, height: reip_image_container_h, duration: duration, ease: 'none' }, 'phase2')
    .to(".reip-drag-draggable.top.left", { top: choose_top_pos, left: choose_left_pos, duration: duration, ease: 'none' }, 'phase2')
    .to(".reip-drag-draggable.bottom.right", { bottom: both_bottom_pos, right: both_right_pos, duration: duration, ease: 'none' }, 'phase2')
    .to(".reip-drag-draggable .label.equity", { left: '-40px' }, 'phase2')
    .to(".reip-drag-draggable .label.both", { left: 0 }, 'phase2')
    .to(".scroll, .choose-both-header", { opacity: 1 }, 'phase2+=1')
    .fromTo(".choose-both-header", {opacity: 0}, { opacity: 1 }, 'phase2+=1')
    if __Purpose.Foundation.MediaQuery.is('small')
      animation_tl.to(window, { scrollTo: {y: 1}, duration: duration, ease: 'none' }, 'phase2')
    animation_tl


  detail_template_sliders: ()=>
    @explore_areas_slider = $(".explore-more-areas_slider")
    @explore_areas_slider.slick
      arrows: false
      autoplay: false
      dots: false
      mobileFirst: true
      pauseOnFocus: false
      pauseOnHover: false
      slidesToScroll: 1
      slidesToShow: 1
      centerMode: true
      # centerPadding: '90px'
      infinite: false
      variableWidth: true
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 1
            slidesToShow: 2
            # centerMode: true
            # centerPadding: '90px'
          }
        }
      ]

    @explore_areas_slider.slick('slickGoTo', @theme) if @theme < 5


    @detail_template_hero_slider = $("#detail_template_hero_slider")
    @detail_template_hero_slider.slick
      arrows: false
      autoplay: false
      dots: true
      mobileFirst: true
      slidesToScroll: 1
      slidesToShow: 1
      infinite: false
      centerMode: false
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            swipe: false
            speed: 2000
            vertical: true
            # variableHeight: false
            # variableWidth: false
          }
        }
      ]
    @detail_template_slick_goto = debounce(1000, (i) =>
      @detail_template_hero_slider.slick('slickGoTo', i)
    )
    @detail_template_hero_slider.slick 'setDimensions'
    @detail_template_hero_slider.fadeTo 'slow', '1'




    if __Purpose.Foundation.MediaQuery.atLeast('large')
      # hides the animation section once we're past it so the page footer can show up
      @timeline(
        scrollTrigger:
          trigger: '#wrap-up'
          start: 'top top'
          end: 'bottom center'
          onEnter: ->
            $('.reip-detail-slider').hide()
          onLeaveBack: ->
            $('.reip-detail-slider').show()
      )

      @detail_template_hero_slider.find '.slick-dot-icon' #ensures slick dots control SVGs
        .on 'click', (e)=>
          clicked = 0
          for i in [0..4]
            dot = @detail_template_hero_slider.find('.slick-dot-icon')[i]
            clicked = i if e.currentTarget is dot
          document.getElementById("slider-spacer-#{clicked}").scrollIntoView()


    else
      # hides the slick dots of the detail slide because they're fixed
      @timeline(
        scrollTrigger:
          trigger: '#wrap-up'
          start: 'top top'
          end: 'bottom center'
          onEnter: =>
            @detail_template_hero_slider.find('.slick-dots').hide()
          onLeaveBack: =>
            @detail_template_hero_slider.find('.slick-dots').show()
      )

  explore_areas_slider_click: (e) =>
    slide = $(e.currentTarget).data('exploreSlide')
    if slide isnt @explore_areas_slider.slick('slickCurrentSlide')
      e.stopImmediatePropagation()
      e.preventDefault()
      @explore_areas_slider.slick('slickGoTo', slide)

  start_jigsaw_timeline: =>
    return if @both_chosen
    @both_chosen = true
    @jigsaw_timeline.play()


  next_section_link_click: (e)=>
    d = $(e.currentTarget).data('nextSection')
    switch d
      when "detail"
        slide = @detail_template_hero_slider.slick('slickCurrentSlide')
        if slide is 4
          document.getElementById('wrap-up').scrollIntoView({behavior: 'smooth'})
        else
          document.getElementById("slider-spacer-#{slide + 1}").scrollIntoView()
      when "explore-area"
        gsap.to("html, body", {scrollTop: $("#explore-area").offset().top - 120, duration: 1})
      else
        document.getElementById(d).scrollIntoView({behavior: 'smooth'})




  nav_choose_both_toggle: (e) ->
    e.preventDefault()
    $('body').toggleClass 'choose-both-nav-expanded'

  explore_area_enter: (e) ->
    e.preventDefault()
    $(e.currentTarget).parent().addClass 'active'

  explore_area_leave: (e) ->
    e.preventDefault()
    $(e.currentTarget).parent().removeClass 'active'

  audio_transcript_toggle: (e) ->
    e.preventDefault()
    $(e.currentTarget).toggleClass('open')
    $(e.currentTarget).next().toggle()

  slider_control: (e)=>
    e.preventDefault()
    direction = $(e.currentTarget).data "direction"

    if direction == "left"
      @explore_areas_slider.slick 'slickPrev'
    else
      @explore_areas_slider.slick 'slickNext'

  svg_transitions: =>
    console.log 'setup svg transitions'
    @svg_timeline = @timeline(
      defaults: @defaults
    )
    .pause()
    .addLabel("slide-0", 0)
    .addLabel("slide-1", "slide-0+=1")
    .addLabel("slide-2", "slide-1+=1")
    .addLabel("slide-3", "slide-2+=1")
    .addLabel("slide-4", "slide-3+=1")

    .fromTo('#slide-text-2',{opacity: 0},{opacity: 1},'slide-0')
    .to('#slide-text-2',{opacity: 0},'slide-1')
    .fromTo('#slide-text-3',{opacity: 0},{opacity: 1},'slide-1')
    .to('#slide-text-3',{opacity: 0},'slide-2')
    .fromTo('#slide-text-4',{opacity: 0},{opacity: 1},'slide-2')
    .to('#slide-text-4',{opacity: 0},'slide-3')
    .fromTo('#slide-text-5',{opacity: 0},{opacity: 1},'slide-3')
    this["svg_trans_#{@theme}"]?.call(this)

  svg_scrolltrigger: =>
    detail_template_hero_slider = @detail_template_hero_slider
    detail_template_slick_goto = @detail_template_slick_goto
    svg_timeline = @svg_timeline
    timeline = @timeline
    timelines = @timelines
    for i in [1..5]
      do (i)=>
        @timeline(
          scrollTrigger:
            trigger: "#slider-spacer-#{i}"
            start: 'top center'
            end: 'top top'
            onEnter: ->
              setTimeout ->
                detail_template_slick_goto(i)
              , 0
              timelines.push svg_timeline.tweenFromTo(svg_timeline.time(), "slide-#{i}", {duration: 2})
            onLeaveBack: ->
              setTimeout ->
                detail_template_slick_goto(i-1)
              , 0
              timelines.push svg_timeline.tweenFromTo(svg_timeline.time(), "slide-#{i-1}", {duration: 2})
        )


  svg_trans_1: =>
    @generate_svg_animation([1..4], [1..9], '#morph-1')

  svg_trans_2: =>
    MorphSVGPlugin.convertToPath("circle, ellipse")
    @generate_svg_animation([1..4], [1..9], '#morph-2')

  svg_trans_3: =>
    MorphSVGPlugin.convertToPath("circle, ellipse, polygon")
    @generate_svg_animation([1..4], [1..16], '#morph-3')

  svg_trans_4: =>
    MorphSVGPlugin.convertToPath("#morph-4 circle, #morph-4 ellipse, #morph-4 polygon")
    @generate_svg_animation([1..4], [1..10], '#morph-4')
    @timelines.push @svg_timeline.fromTo("#morph-4", {height: '100vh'}, {height: 'auto', duration: 0.95}, 'slide-0')
    @timelines.push @svg_timeline.to("#t11, #t13, #t14, #t15, #t16, #t17, #t18, #t19, #t110", {fill: '#62646A', duration: 0.95}, 'slide-2')
    @timelines.push @svg_timeline.to("#t11, #t13, #t14, #t15, #t16, #t17, #t18, #t19, #t110", {fill: '#1A1C24', duration: 0.95}, 'slide-3')

  svg_trans_5: =>
    MorphSVGPlugin.convertToPath("#morph-5 circle")
    @generate_svg_animation([1..4], [1..25], '#morph-5')
    @timelines.push @svg_timeline.to("#t11, #t12", {opacity: 0, duration: 0.95}, 'slide-2')

  generate_svg_animation: (s_range, i_range, svg_selector) =>
    for s in s_range
      for i in i_range
        @timelines.push @svg_timeline.to("#{svg_selector} #t1#{i}", {morphSVG: {shape: "#{svg_selector} #t#{s+1}#{i}", type: "linear"}, duration: 0.95}, "slide-#{s-1}")

  slick_controls_svgs: =>
    @detail_template_hero_slider.on 'click', @detail_slider_click
    @detail_template_hero_slider.on 'beforeChange', @detail_slider_change


  detail_slider_click: =>
    slide = @detail_template_hero_slider.slick('slickCurrentSlide')
    if(slide < 4)
      @detail_template_hero_slider.slick('slickNext')
      @svg_timeline.tweenFromTo(@svg_timeline.time(), "slide-#{slide + 1}")
    else
      document.querySelector('.wrap-up-audio').scrollIntoView({behavior: 'smooth'})
      @detail_template_hero_slider.find('.slick-dots').fadeOut()

  detail_slider_change: (evt, slk, cur, nxt)=>
    if cur is 3
      $('body').removeClass('scroll-locked')
      @detail_template_hero_slider.find('.slick-dots').addClass('text-tap-scroll')
    @timelines.push @svg_timeline.tweenFromTo(@svg_timeline.time(), "slide-#{nxt}", {duration: 2})

  footer_content_toggle: (e)->
    e.preventDefault()
    $(e.currentTarget).toggleClass 'expanded'
    $(e.currentTarget).next().toggleClass 'expanded'


  getTimeCodeFromNum: (num) ->
    seconds = parseInt(num, 10)
    minutes = parseInt(seconds / 60, 10)
    seconds -= minutes * 60
    hours = parseInt(minutes / 60, 10)
    minutes -= hours * 60
    if (hours == 0)
      return "#{minutes}:#{String(seconds % 60).padStart(2, 0)}"
    else
      return "#{String(hours).padStart(2, 0)}:#{minutes}:#{String(seconds % 60).padStart(2, 0)}"

  toggle_audio: () ->
    playButton = $('.audio-player .controls .toggle-play')
    playButton.click()

  audioplayer: ()=>
    audioPlayer = document.querySelector('.audio-player')
    @audio = new Audio(@audio_file)

    @audio.addEventListener 'loadeddata', (=>
      audioPlayer.querySelector('.time .length').textContent = @getTimeCodeFromNum(@audio.duration)
      @audio.volume = .95
      return
    ), false

    #click on timeline to skip around
    timeline = audioPlayer.querySelector('.timeline')
    timeline.addEventListener 'click', ( (e)=>
      timelineWidth = window.getComputedStyle(timeline).width
      timeToSeek = e.offsetX / parseInt(timelineWidth, 10) * @audio.duration
      @audio.currentTime = timeToSeek
      return
    ), false

    #click volume slider to change volume
    # volumeSlider = audioPlayer.querySelector('.controls .volume-slider')
    # volumeSlider.addEventListener 'click', ( (e)->
    #   sliderWidth = window.getComputedStyle(volumeSlider).width
    #   newVolume = e.offsetX / parseInt(sliderWidth)
    #   audio.volume = newVolume
    #   audioPlayer.querySelector('.controls .volume-percentage').style.width = newVolume * 100 + '%'
    #   return
    # ), false

    playBtn = audioPlayer.querySelector('.controls .toggle-play')
    ctaHearAScenario = document.querySelector('.cta-hear-a-scenario')

    #check audio percentage and update time accordingly
    setInterval( ()=>
      progressBar = audioPlayer.querySelector('.progress')
      progressBar.style.width = @audio.currentTime / @audio.duration * 100 + '%'
      audioPlayer.querySelector('.time .current').textContent = @getTimeCodeFromNum(@audio.currentTime)
      if @audio.currentTime == @audio.duration
        playBtn.classList.remove 'pause'
        playBtn.classList.add 'play'
        ctaHearAScenario.classList.remove 'play'
      return
    , 500)

    #toggle between playing and pausing on button click
    playBtn.addEventListener 'click', (=>
      if @audio.paused
        playBtn.classList.remove 'play'
        playBtn.classList.add 'pause'
        ctaHearAScenario.classList.add 'play'
        @audio.play()
      else
        playBtn.classList.remove 'pause'
        playBtn.classList.add 'play'
        ctaHearAScenario.classList.remove 'play'
        @audio.pause()
      return
    ), false

    # audioPlayer.querySelector('.volume-button').addEventListener 'click', ->
    #   volumeEl = audioPlayer.querySelector('.volume-container .volume')
    #   audio.muted = !audio.muted
    #   if audio.muted
    #     volumeEl.classList.remove 'icon-volumeMedium'
    #     volumeEl.classList.add 'icon-volumeMute'
    #   else
    #     volumeEl.classList.add 'icon-volumeMedium'
    #     volumeEl.classList.remove 'icon-volumeMute'
    #   return


  # To prevent garbage in the top part of the landing page on resize,
  # we will reload if
  # - the new breakpoint is different than the old breakpoint
  # - we are not scrolled so far down the page that the infro section is not visible
  resize_reloader: =>
    if @is_landing
      last_breakpoint = @breakpoint
      @set_last_breakpoint()
      console.log last_breakpoint, @breakpoint
      if (last_breakpoint isnt @breakpoint) \
          and ($(window).scrollTop() + $(window).height()) < $(".explore-area-block.section-01").offset().top
        $('body').addClass('op0')
        window.history.go(0)
    else
      console.log 'bl'

  set_last_breakpoint: =>
    @breakpoint =  __Purpose.Foundation.MediaQuery.current
    if @breakpoint is 'xxlarge'
      @breakpoint = 'xlarge'


  scroll_to_guide: ->
    document.querySelector('#you_can_choose_both')
      .scrollIntoView({
        behavior: 'smooth'
      })


  receiveMessage: (event)->
    if event.origin is window.location.origin and event?.data?.type is "reip_frameworks_submit"
      window.open event?.data?.download, "_blank", "popup"


  # create a GSAP timeline and keep track of it so we can play nice with turbolinks
  timeline: (params) =>
    tl = gsap.timeline(params)
    @timelines.push tl
    tl



window.reip = new REIP
